import { useEffect, useReducer } from 'react'
import { WarningFilled } from "@ant-design/icons"
import Loader from "components/Loader"
import ScrollX from "components/ScrollX";
import { Grid, Alert, Box, InputBase } from "@mui/material"
import { useSelector } from 'react-redux';
// import { get } from 'lodash';
import { PAGINATION } from 'utils/constants/pagination';
import MainCard from 'components/MainCard';
import Timesheet from './timesheet';
import { dispatch } from 'store';
import { fetchTimesheet } from 'store/reducers/timesheet';
import { get } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import T from 'utils/constants/T';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    border: "1px solid #d9d9d9",
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 1.5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create('width'),
        width: '100%'
    },
}));

const TimesheetPage = () => {
    const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
    const { companyTabData, timesheet: timesheetStore } = useSelector((state: any) => state);
    const companyId = get(selectedCompany, "_id", "");
    const { systemPayrollCode } = companyTabData;
    const { timesheet, page: storePage, pageSize: storePageSize, totalRecords, totalPages } = timesheetStore;
    const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
console.log(storePage, storePageSize, totalRecords, totalPages)
    const [localState, setLocalState] = useReducer(
        (prevState: any, newState: any) => ({ ...prevState, ...newState }),
        {
            pageNumber: INITIAL_PAGE,
            rowsPerPage: ROWS_PER_PAGE,
            isLoading: false,
            employeeName: '',
        }
    );

    const { pageNumber, rowsPerPage, isLoading, employeeName } = localState;

    const fetchData = async () => {
        setLocalState({ isLoading: true });
        await dispatch(fetchTimesheet(pageNumber, rowsPerPage, companyId, employeeName));
        setLocalState({ isLoading: false });
    };

    const handlePageChange = async (page: number) => {
        setLocalState({ pageNumber: page });
    };

    const handleRowsPerPageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        setLocalState({ pageNumber: INITIAL_PAGE, rowsPerPage: value });
    };

    const handleEmployeeNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        let debounceTimer : any = null;
        clearTimeout(debounceTimer);
        setLocalState({ employeeName: value });

        if (value.length >= 5 || value.length === 0) {
            debounceTimer = setTimeout(() => {
                setLocalState({ pageNumber: INITIAL_PAGE });
            }, 500);
        }
    };


    useEffect(() => {
        if (companyId) {
            fetchData();
        }
    }, [pageNumber, rowsPerPage, employeeName, companyId]);

    return (
        <>
            {isLoading && <Loader />}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {!companyId && (
                        <Alert color="warning" icon={<WarningFilled />}>
                            Please select a company first
                        </Alert>
                    )}
                    {companyId && (
                        <MainCard>
                            <Box sx={{ display: 'flex', gap: 1, marginLeft: '20px' }}>
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder={T.EMPLOYEE}
                                        name="employeeName"
                                        value={employeeName}
                                        inputProps={{ "aria-label": "search" }}
                                        onChange={handleEmployeeNameSearch}
                                    />
                                </Search>
                            </Box>
                            <ScrollX>
                                <Timesheet
                                    timesheet={timesheet}
                                    page={pageNumber}
                                    pageSize={rowsPerPage}
                                    totalRecords={totalRecords}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    systemPayrollCode={systemPayrollCode}
                                />
                            </ScrollX>
                        </MainCard>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default TimesheetPage;


// const TimesheetPage = () => {

//     // const { selectedCompany } = useSelector((state: any) => state.dashboardSearch)
//     const { selectedCompany } = useSelector((state: any) => state.dashboardSearch)
//     const { companyTabData, timesheet: timesheetStore } = useSelector((state: any) => state)
//     const companyId = get(selectedCompany, "_id", "");
//     const { systemPayrollCode } = companyTabData
//     console.log('company id', companyId)
//     const { timesheet, page, pageSize, totalRecords, totalPages } = timesheetStore
//     console.log('timesheet fetch from store are', timesheet)
//     const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION

//     const [localState, setLocalState] = useReducer(
//         (prevState: any, newState: any) => ({ ...prevState, ...newState }),
//         {
//             pageNumber: page,
//             rowsPerPage: pageSize,
//             isLoading: false,
//             employeeName:''
//         }
//     );

//     const { rowsPerPage, isLoading,employeeName } = localState

//     const handlePageChange = async (page: number) => {
//         setLocalState((prevState: any) => ({ ...prevState, page, isLoading: true }));
//         await dispatch(fetchTimesheet(page, rowsPerPage, companyId, employeeName));
//         setLocalState((prevState: any) => ({ ...prevState, isLoading: false }));
//     };
    
//     const handleRowsPerPageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
//         const value = Number(event.target.value);
//         setLocalState((prevState: any) => ({
//             ...prevState,
//             page: INITIAL_PAGE,
//             rowsPerPage: value,
//             isLoading: true,
//         }));
//         await dispatch(fetchTimesheet(INITIAL_PAGE, value, companyId, employeeName));
//         setLocalState((prevState: any) => ({ ...prevState, isLoading: false }));
//     };
    

//     let debounceTimer: any = null;
//     const handleEmployeeNameSearch = (employeeName: any) => {
//         console.log(
//             "I AM HERE"
//         )
//         const { name, value } = employeeName.target;
//         setLocalState({ [name]: value })
//         clearTimeout(debounceTimer);
//         setLocalState({ isLoading: false })

//         console.log(value)
//         if (value?.length >= 5 || value?.length === 0) {
//             setLocalState({ isLoading: true })
//             debounceTimer = setTimeout(() => {
//                 dispatch(fetchTimesheet(INITIAL_PAGE, ROWS_PER_PAGE, companyId, value))
//                 setLocalState({ isLoading: false })
//             }, 500)
//         }

//     }

//     // fetch timesheet as soon page is loaded
//     useEffect(() => {
//         const fetchTimesheets = async () => {
//             setLocalState({ isLoading: true })
//             await dispatch(fetchTimesheet(INITIAL_PAGE, ROWS_PER_PAGE, companyId))
//             setLocalState({ isLoading: false })
//         }

//         if (companyId) fetchTimesheets()
//     }, [companyId])


//     return (
//         <>
//             {(isLoading) && <Loader />}
//             <Grid container spacing={3}>
//                 <Grid item xs={12}>

//                     {!companyId && (
//                         <Alert color="warning" icon={<WarningFilled />}>
//                             Please select a company first
//                         </Alert>
//                     )}

//                     {companyId && (
//                         <MainCard>
//                              <Box sx={{ display: 'flex', gap: 1 }}>
//                              <Box sx={{ marginLeft: '20px' }}>
//                                 <Search>
//                                     <SearchIconWrapper>
//                                         <SearchIcon />
//                                     </SearchIconWrapper>
//                                     <StyledInputBase
//                                         placeholder={T.EMPLOYEE}
//                                         name="employeeName"
//                                         value={employeeName}
//                                         inputProps={{ "aria-label": "search" }}
//                                         onChange={handleEmployeeNameSearch}
//                                     />
//                                 </Search>
//                             </Box>
//                              </Box>

//                             <ScrollX>
//                                 <Timesheet
//                                     timesheet={timesheet}
//                                     page={page}
//                                     pageSize={pageSize}
//                                     totalRecords={totalRecords}
//                                     totalPages={totalPages}
//                                     onPageChange={handlePageChange}
//                                     onRowsPerPageChange={handleRowsPerPageChange}
//                                     systemPayrollCode={systemPayrollCode}

//                                 />
//                             </ScrollX>
//                         </MainCard>
//                     )}

//                 </Grid>
//             </Grid>
//         </>
//     )
// }

// export default TimesheetPage