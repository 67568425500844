import React, { useEffect, useReducer, useMemo, useCallback } from "react";
import { Typography, Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { get } from "lodash";
import EditBankingTable from "./EditBankingTable";
import { dispatch } from "store";
import T from "utils/constants/T";
import CashDDialog from "components/common/CashDDialog";
import {
  UpdateCompanyLender,
  fetchLendersDetailedList,
} from "store/reducers/companyBankingSettings";

import "./banking.css";
import { decryptBankingData } from "utils/helper";

interface ChangeLenderDetailsProps {
  selectedCompanyId?: string;
  handleLenderChangeModal?: () => void;
}

const ChangeLenderDetails: React.FC<ChangeLenderDetailsProps> = ({
  selectedCompanyId = "",
  handleLenderChangeModal = () => {},
}) => {
  // Local state management using useReducer
  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      openLenderChangeConfirmationModal: false,
      newLenderId: "",
      newLenderAccountName: "",
    }
  );

  const {
    openLenderChangeConfirmationModal,
    newLenderId,
    newLenderAccountName,
  } = localState;

  // Selectors for state
  const { lendersList, paymentPartner } = useSelector(
    (state: any) => state.bankingSettingsTabData
  );

  // Memoized table row data computation
  const allTableRows = useMemo(() => {
    return lendersList.map((row: any) => {
      const creditVal = get(row, "total_amount", 0);
      const debitVal = get(row, "total_deduction", 0);
      const balanceVal = creditVal - debitVal;
      const iRateVal = get(row, "interest_rate_value", "");
      const iRateType =
        get(row, "interest_rate_type", "") === T.DOLLARTEXT
          ? T.DOLLAR
          : T.PERCENTAGE;
      const finalInterestRate =
        iRateType === T.DOLLAR
          ? `${iRateType}${iRateVal}`
          : `${iRateVal}${iRateType}`;

      let accountName, bsb, accountNumber;
      if (paymentPartner === T.MONOOVA) {
        accountName = get(row, "test_receivables_account_name", "");
        bsb = decryptBankingData(get(row, "test_receivables_account_bsb", ""));
        accountNumber = decryptBankingData(
          get(row, "test_receivables_account_number", "")
        );
      } else if (paymentPartner === T.NIUM) {
        accountName = get(row, "lender_name", "");
        accountNumber = get(row, "nium_sandbox_account_number", "");
      }

      return {
        lenderId: get(row, "_id", ""),
        accountName,
        fundingType: get(row, "funding_type", ""),
        nameOfBank: paymentPartner,
        ...(bsb && { bsb }),
        accountNumber,
        balance: balanceVal,
        interestRate: finalInterestRate,
      };
    });
  }, [lendersList, paymentPartner]);

  // Fetch lenders list on component mount or when selectedCompanyId changes
  useEffect(() => {
    dispatch(fetchLendersDetailedList(selectedCompanyId));
  }, [selectedCompanyId]);

  // Update lender function
  const lenderUpdate = useCallback(
    (newLenderId: string) => {
      const payload = {
        newLenderId,
        companyId: selectedCompanyId,
        providerType: paymentPartner,
      };
      dispatch(UpdateCompanyLender(payload));
    },
    [selectedCompanyId, paymentPartner]
  );

  // Handle adding a new lender
  const handleAddChangeLender = useCallback(
    (newLenderId: string, newLenderAccountName: string) => {
      setLocalState({ newLenderId, newLenderAccountName });
      handleLenderChangeConfirmationModal();
    },
    []
  );

  // Toggle lender change confirmation modal
  const handleLenderChangeConfirmationModal = useCallback(() => {
    setLocalState({
      openLenderChangeConfirmationModal:
        !localState.openLenderChangeConfirmationModal,
  });
  }, [localState]);

  // Confirm lender change
  const handleChangeLenderConfirmation = useCallback(() => {
    lenderUpdate(newLenderId);
    handleLenderChangeConfirmationModal();
    handleLenderChangeModal();
  }, [
    lenderUpdate,
    newLenderId,
    handleLenderChangeConfirmationModal,
    handleLenderChangeModal,
  ]);

  return (
    <>
      <EditBankingTable
        allTableRows={allTableRows}
        handleAddChangeLender={handleAddChangeLender}
      />

      <CashDDialog
        open={openLenderChangeConfirmationModal}
        handleClose={handleLenderChangeConfirmationModal}
      >
        <Typography>
          {`${T.ARE_YOU_SURE_YOU_WANT_TO_ADD} ${newLenderAccountName}`}
        </Typography>
        <Box className="confirmation_modal">
          <Button
            variant="outlined"
            onClick={handleLenderChangeConfirmationModal}
          >
            {T.NO}
          </Button>
          <Button variant="contained" onClick={handleChangeLenderConfirmation}>
            {T.YES}
          </Button>
        </Box>
      </CashDDialog>
    </>
  );
};

export default React.memo(ChangeLenderDetails);
