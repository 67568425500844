import T from "./T";

const {
  COMPANY_NAME,
  DATE,
  TIME,
  PAYMENT_ID,
  DEBIT,
  FEE,
  STATUS,
  PAY_PERIOD,
  EMPLOYEE,
  PAYROLL_ID,
  SCHEDULE,
} = T;

export const TRANSACTION_TABLE_HEADER = [
  // { label: S_NO },
  { label: `${DATE} & ${TIME}` },
  { label: COMPANY_NAME },
  { label: PAY_PERIOD },
  { label: SCHEDULE },
  { label: EMPLOYEE },
  { label: PAYROLL_ID, align: "center" },
  // { label: `${MONOOVA} ${TRANSACTION_ID}`,align:"center"},
  { label: PAYMENT_ID, align: "center" },
  { label: DEBIT },
  { label: FEE },
  { label: STATUS },
];
