//react
import React ,{ useEffect, useCallback, useMemo } from 'react';

//Material-UI
import { Grid, Button, Divider, Typography } from '@mui/material';
// import CachedIcon from '@mui/icons-material/Cached';

//local imports
import MainCard from 'components/MainCard';
import CashDCard from 'components/common/CashDCard';
import { useNavigate } from 'react-router';
import T from 'utils/constants/T';
import { StyledBodyTypography, StyledTitleTypography } from '../CompanyTab/CompanyInfo';
import {
  fetchPaymentPartnersList,
  getPaymentPartnersById,
} from "store/reducers/companyBankingSettings";
import ViewBankingDetails from "./ViewBankingDetails";
import {  useDispatch } from "react-redux";
import "../../companyDetails.css";
import "./banking.css";
import { useSelector } from 'store';

const ViewBankingSettings = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { companyName, bankingEnvironment, paymentSystemId } = useSelector(
    (state: any) => state.companyTabData
  );
  const { role } = useSelector((state) => state.auth) as {
    role: string;
  };
  const {
    paymentPartner,
    accountNumber,
    lenderId,
    fundingType,
    nameOfBank,
    balance,
    accountName,
    bsb,
    interestRate,
  } = useSelector((state: any) => state.bankingSettingsTabData);

  const BANK_ENV = useMemo(
    () => (bankingEnvironment ? T.LIVE : T.TEST),
    [bankingEnvironment]
  );

  useEffect(() => {
    dispatch(fetchPaymentPartnersList());
  }, [dispatch]);

  useEffect(() => {
    if (paymentSystemId) {
      dispatch(getPaymentPartnersById(paymentSystemId));
    }
  }, [paymentSystemId, dispatch]);

  const handleEdit = useCallback(() => {
    navigate("/companyDetails/bankingSettings/edit");
  }, [navigate]);

  const renderViewBankingData = useMemo(
    () => ({
      accountName,
      fundingType,
      nameOfBank,
      bsb,
      accountNumber,
      balance,
      interestRate,
    }),
    [
      accountName,
      fundingType,
      nameOfBank,
      bsb,
      accountNumber,
      balance,
      interestRate,
    ]
  );

  return (
    <MainCard
      title={companyName}
      secondary={
        <>
        {role === "superadmin" &&
        <Button
          variant="contained"
          className="main-card-button"
          onClick={handleEdit}
        >
          {T.EDIT}
        </Button>
      }
        </>
      }
    >
      <CashDCard borderRadius="20px">
        <Grid container rowSpacing={1}>
          <Grid item xs={6}>
            <StyledTitleTypography>{T.ENVIRONMENT}</StyledTitleTypography>
            <StyledBodyTypography>{BANK_ENV}</StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>{T.PAYMENT_PARTNER}</StyledTitleTypography>
            <StyledBodyTypography>{paymentPartner}</StyledBodyTypography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </CashDCard>
      {lenderId ? (
        <CashDCard borderRadius="20px">
          <ViewBankingDetails {...renderViewBankingData} />
        </CashDCard>
      ) : (
        <CashDCard borderRadius="20px">
          <Typography className="lender-heading">{T.LENDER}</Typography>
          <Typography className="lender-typo">{T.DOES_NOT_EXIST}</Typography>
        </CashDCard>
      )}
    </MainCard>
  );
});

export default React.memo(ViewBankingSettings);