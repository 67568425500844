//Material UI
import Grid from "@mui/material/Grid";

//Local imports
import T from "utils/constants/T";
import CashDTextField from "components/common/CashDTextfield";
import { StyledBodyTypography } from "./CompanyInfo";

//lodash
import { noop } from "lodash";
import { isAlphabtetic } from "utils/constants/validations";
import CashDAutocomplete from "components/common/CashDAutocomplete";
import { useDispatch } from "react-redux";
import { useSelector } from "store";
import {
  addNewCountry,
  getCountryDetails,
} from "store/reducers/companyTabDetails";
import { useEffect, useState } from "react";
import CashDDialog from "components/common/CashDDialog";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";

interface EditCompanyInfoProps {
  contactPerson?: string;
  payrollSystem?: string;
  email?: string;
  attendanceSystem?: string;
  phoneNumber?: string;
  city?: string;
  abn?: string;
  onHandleChange?: (e: any) => void;
  country_name?: string;
  editCountryDetails?: boolean;
  enable_country_feature?: boolean;
  onHandleAutoCompleteChange?: (type: any, value: any) => void;
  handleEditCompanyDetails?: (type: any, value: any) => void;
  countryList?: any[];
  settingsList?: any[];
  openAddNewCountryModal?: boolean;
  handleCloseAddNewCountryModal?: (e: any) => void;
}
const EditCompanyInfo = ({
  contactPerson = "",
  payrollSystem = "",
  email = "",
  attendanceSystem = "",
  phoneNumber = "",
  city = "",
  abn = "",
  onHandleChange = noop,
  country_name = "",
  editCountryDetails = false,
  onHandleAutoCompleteChange = noop,
  handleEditCompanyDetails = noop,
  enable_country_feature = false,
  countryList = [],
  settingsList = [],
  openAddNewCountryModal = false,
  handleCloseAddNewCountryModal = noop,
}: EditCompanyInfoProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigator = (path: string) => {
    navigate(path);
    setIsModalOpen(false);
  };
  const { role } = useSelector((state) => state.auth) as {
    onBoardedCompanyData: any;
    role: string;
  };

  useEffect(() => {
    dispatch(getCountryDetails());
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCountryChange = (type: any, value: any) => {
    if (value === T.ADD_NEW_COUNTRY) {
      setIsModalOpen(true); 
    } else {
      setIsModalOpen(false); 
    }
    onHandleAutoCompleteChange("country_name", value);
  };

  const handleModalClose = () =>{
    setIsModalOpen(false);
  }

  const validationSchema = Yup.object({
    countryName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Country name must be alphabetic")
      .required("Country name is a required field"),
    countryCurrencyCode: Yup.string()
      .matches(/^[A-Za-z]+$/, "Currency code must be alphabetic")
      .required("ABN is a required field"),
    currencySymbol: Yup.string()
      .required("Symbol is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      countryName: "",
      countryCurrencyCode: "",
      currencySymbol: "",
    },
    validationSchema,
    onSubmit: async (payload, {resetForm}) => {
      await dispatch(addNewCountry(payload, navigator));
      resetForm();
      // setOpen(true);
    },
  });
  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.CONTACT_PERSON}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <CashDTextField
              required
              fullWidth
              placeholder={T.CONTACT_PERSON}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="contactPerson"
              value={contactPerson}
              error={!isAlphabtetic(contactPerson)}
              errorText={
                !isAlphabtetic(contactPerson) ? "Name must be alphabetic" : ""
              }
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.PAYROLL_SYSTEM}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{payrollSystem}</StyledBodyTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.EMAIL}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <CashDTextField
              required
              fullWidth
              placeholder={T.EMAIL}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="email"
              value={email}
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.ATTENDANCE_SYSTEM}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledBodyTypography>{payrollSystem}</StyledBodyTypography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.PHONE_NUMBER}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <CashDTextField
              required
              fullWidth
              type="number"
              placeholder={T.PHONE_NUMBER}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.CITY}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <CashDTextField
              required
              fullWidth
              placeholder={T.CITY}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="city"
              value={city}
              error={!isAlphabtetic(city)}
              errorText={!isAlphabtetic(city) ? "City must be alphabetic" : ""}
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.ABN}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <CashDTextField
              required
              fullWidth
              placeholder={T.ABN}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="abn"
              value={abn}
              type="number"
              onChange={onHandleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid container>
          <Grid item xs={6}>
            <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
              {T.COUNTRY_NAME}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            {role === "superadmin" ||
            (role === "employer" && editCountryDetails) ? (
              <CashDAutocomplete
                placeholder={T.COUNTRY_NAME}
                fullWidth
                displayRemoveButton="none"
                listDetails={
                  role === "superadmin"
                    ? [
                        ...countryList.map(
                          (country: any) => country.countryName
                        ),
                        T.ADD_NEW_COUNTRY,
                      ]
                    : countryList.map((country: any) => country.countryName)
                }
                value={country_name}
                getByLabelText={(option: any) => option}
                onHandleChange={handleCountryChange}
              />
            ) : (
              <StyledBodyTypography>{country_name}</StyledBodyTypography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {role === "superadmin" && (
        <Grid
          item
          xs={6}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid container>
            <Grid item xs={6}>
              <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
                {T.EDIT_COUNTRY}
              </StyledBodyTypography>
            </Grid>
            <Grid item xs={6}>
              <CashDAutocomplete
                placeholder={T.EDIT_COUNTRY}
                fullWidth
                listDetails={settingsList}
                value={enable_country_feature ? T.YES : T.NO}
                getByLabelText={(option: any) => option}
                onHandleChange={(type: any, value: any) => {
                  handleEditCompanyDetails("enable_country_feature", value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <CashDDialog
        open={isModalOpen}
        handleClose={handleCloseAddNewCountryModal}
      >
        <>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4" sx={{ mt: 2, textAlign: "center" }}>
              Enter Country Details
            </Typography>
            <Stack spacing={2}>
              <Stack padding={2}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={5}>
                    <StyledBodyTypography>
                      {T.COUNTRY_NAME}
                    </StyledBodyTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      name="countryName"
                      fullWidth
                      label="Enter Country"
                      value={formik.values.countryName}
                      variant="outlined"
                      error={
                        formik.touched.countryName &&
                        Boolean(formik.errors.countryName)
                      }
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.countryName && formik.errors.countryName
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack padding={2}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={5}>
                    <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
                      {T.CURRENCY_CODE}
                    </StyledBodyTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      name="countryCurrencyCode"
                      fullWidth
                      label="Enter Country Code"
                      value={formik.values.countryCurrencyCode}
                      variant="outlined"
                      error={
                        formik.touched.countryCurrencyCode &&
                        Boolean(formik.errors.countryCurrencyCode)
                      }
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.countryCurrencyCode &&
                        formik.errors.countryCurrencyCode
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Stack padding={2}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                  <Grid item xs={5}>
                    <StyledBodyTypography sx={{ flexWrap: "nowrap" }}>
                      {T.CURRENCY_SYMBOL}
                    </StyledBodyTypography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      name="currencySymbol"
                      fullWidth
                      label="Enter Currency Symbol"
                      value={formik.values.currencySymbol}
                      variant="outlined"
                      error={
                        formik.touched.currencySymbol &&
                        Boolean(formik.errors.currencySymbol)
                      }
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.currencySymbol &&
                        formik.errors.currencySymbol
                      }
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Button
                // variant="outlined"
                color="secondary"
                onClick={handleModalClose}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </>
      </CashDDialog>
    </Grid>
  );
};

export default EditCompanyInfo;
