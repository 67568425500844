//RTK
import { createSlice } from "@reduxjs/toolkit";
import authApi from "interceptor/authInterceptor";

//Lodash
import { get } from "lodash";

//Local imports
import { dispatch } from "store";
import T from "utils/constants/T";

// import ServerConstants from "utils/ServerConstants";
import toast from "utils/ToastNotistack";

// types
export type companyTabProps = {
  companyId: string;
  companyName: string;
  contactPerson: string;
  companyEmail: string;
  companyPhone: string;
  companyMemberSince: string;
  city: string;
  abn: string;
  systemName: string;
  totalNumberOfEmployees: string | number;
  allowablePercentageDrawdown: number | string;
  minimumWithdrawl: string | number;
  transactionFee: string | number;
  floatAlertThresholdAmount: string | number;
  totalAmountPerCompany: string | number;
  floatAlertRecipients: string;
  transactionFeeType: string;
  systemPayrollId: string;
  systemPayrollCode: string;
  cashDCapitalId: string;
  paymentSystemId: string;
  primaryColor: string;
  secondaryColor: string;
  logoUrl: string;
  cashDFeeId: string;
  activePayroll: boolean;
  systemCode: string;
  includeSubmitted: Boolean;
  bankingEnvironment: boolean;
  bankingValidateBankAccount: boolean;
  loader: Boolean;
  error: null;
  custom: boolean;
  emailLogo: string;
  apiUserName: string;
  apiPassword: string;
  apiKey: string;
  baseUrl: string;
  userName: string;
  userPassword: string;
  countryName : string;
  currencyCode: string;
  currencySymbol: string;
  editCountryDetails : boolean;
  countryList : [];
  countryID: string;
};

// initial state
const initialState: companyTabProps = {
  companyId: "",
  companyName: "",
  contactPerson: "",
  companyEmail: "",
  companyPhone: "",
  companyMemberSince: "",
  city: "",
  abn: "",
  systemName: "",
  allowablePercentageDrawdown: "",
  minimumWithdrawl: "",
  transactionFee: "",
  transactionFeeType: "$",
  floatAlertThresholdAmount: "",
  floatAlertRecipients: "",
  totalNumberOfEmployees: "",
  totalAmountPerCompany: "",
  systemPayrollId: "",
  systemPayrollCode: "",
  cashDCapitalId: "",
  cashDFeeId: "",
  activePayroll: false,
  systemCode: "",
  paymentSystemId: "",
  primaryColor: "",
  secondaryColor: "",
  logoUrl: "",
  bankingEnvironment: false,
  bankingValidateBankAccount: false,
  includeSubmitted: false,
  loader: false,
  error: null,
  custom: false,
  emailLogo: "",
  apiKey:"",
  apiPassword:"",
  apiUserName: "",
  baseUrl: "",
  userName: "",
  userPassword: "", 
  countryName : "",
  currencyCode: "",
  currencySymbol: "",
  editCountryDetails : false,
  countryList : [],
  countryID: ""
};

// ==============================|| SLICE - Company Details Tab ||============================== //

const companyTabData = createSlice({
  name: "companyTabData",
  initialState,
  reducers: {
    getCompanyTabData(state, action) {
      let {
        companyId,
        companyName,
        companyEmail,
        companyPhone,
        companyMemberSince,
        city,
        abn,
        systemName,
        contactPerson,
        allowablePercentageDrawdown,
        minimumWithdrawl,
        transactionFee,
        transactionFeeType,
        floatAlertThresholdAmount,
        totalNumberOfEmployees,
        totalAmountPerCompany,
        floatAlertRecipients,
        systemPayrollId,
        systemPayrollCode,
        cashDCapitalId,
        cashDFeeId,
        activePayroll,
        systemCode,
        includeSubmitted,
        apiKey,
        apiUserName,
        apiPassword,
        baseUrl,
        userName,
        userPassword,
        countryName,
        currencyCode,
        currencySymbol,
        editCountryDetails,
        countryID,
        paymentSystemId
      } = action.payload;
      state.companyId = companyId || initialState.companyId;
      state.companyName = companyName || initialState.companyName;
      state.contactPerson = contactPerson || initialState.contactPerson;
      state.companyEmail = companyEmail || initialState.companyEmail;
      state.companyPhone = companyPhone || initialState.companyPhone;
      state.companyMemberSince =
        companyMemberSince || initialState.companyMemberSince;
      state.city = city || initialState.city;
      state.abn = abn || initialState.abn;
      state.systemName = systemName || initialState.systemName;
      state.allowablePercentageDrawdown =
        allowablePercentageDrawdown || initialState.allowablePercentageDrawdown;
      state.minimumWithdrawl =
        minimumWithdrawl || initialState.minimumWithdrawl;
      state.transactionFee = transactionFee || initialState.transactionFee;
      state.transactionFeeType =
        transactionFeeType || initialState.transactionFeeType;
      state.floatAlertThresholdAmount =
        floatAlertThresholdAmount || initialState.floatAlertThresholdAmount;
      state.totalNumberOfEmployees =
        totalNumberOfEmployees || initialState.totalNumberOfEmployees;
      state.totalAmountPerCompany =
        totalAmountPerCompany || initialState.totalAmountPerCompany;
      state.floatAlertRecipients =
        floatAlertRecipients || initialState.floatAlertRecipients;
      state.systemPayrollId = systemPayrollId || initialState.systemPayrollId;
      state.systemPayrollCode =
        systemPayrollCode || initialState.systemPayrollCode;
      state.cashDCapitalId = cashDCapitalId || initialState.cashDCapitalId;
      state.cashDFeeId = cashDFeeId || initialState.cashDFeeId;
      state.activePayroll = activePayroll || initialState.activePayroll;
      state.systemCode = systemCode || initialState.systemCode;
      state.includeSubmitted =
        includeSubmitted || initialState.includeSubmitted;
        state.apiKey = apiKey || initialState.apiKey;
        state.apiUserName = apiUserName || initialState.apiUserName;
        state.apiPassword = apiPassword || initialState.apiPassword;
        state.baseUrl = baseUrl || initialState.baseUrl;
        state.userName = userName || initialState.userName;
        state.userPassword = userPassword || initialState.userPassword;
        state.countryName = countryName || initialState.countryName;
        state.currencyCode = currencyCode || initialState.currencyCode;
        state.currencySymbol = currencySymbol || initialState.currencySymbol;
        state.editCountryDetails = editCountryDetails || initialState.editCountryDetails;
        state.countryID = countryID || initialState.countryID;
        state.paymentSystemId = paymentSystemId || initialState.paymentSystemId;
    },
    getBankingTabData(state, action) {
      let { bankingEnvironment, bankingValidateBankAccount, paymentSystemId } =
        action.payload;
      state.bankingEnvironment =
        bankingEnvironment || initialState.bankingEnvironment;
      state.bankingValidateBankAccount =
        bankingValidateBankAccount || initialState.bankingValidateBankAccount;
      state.paymentSystemId = paymentSystemId || initialState.paymentSystemId;
    },
    getWhiteLabelTabData(state, action) {
      let { primaryColor, logoUrl, secondaryColor, custom } = action.payload;
      state.primaryColor = primaryColor || initialState.primaryColor;
      state.secondaryColor = secondaryColor || initialState.secondaryColor;
      state.logoUrl = logoUrl || initialState.logoUrl;
      state.custom = custom || initialState.custom;
    },
    getEmailtemplate(state, action) {
      let { emailLogo } = action.payload;
      state.emailLogo = emailLogo || initialState.emailLogo;
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearCompany(state) {
      return initialState;
    },
    updatePaymentSystemId: (state, action) => {
      state.paymentSystemId = action.payload;
    },
    updateCompanyDetails: (state: any, actions) => {
      const { key, value } = actions.payload;
      state[key] = value;
    },
    clearCompanyTabDetails: (state) => initialState,
    getCountryDetailsSuccess(state, action) {
      let { countryList } = action.payload;

      state.countryList = countryList || initialState.countryList;
    }
  },
});

export default companyTabData.reducer;

export const {
  getCompanyTabData,
  getBankingTabData,
  clearCompany,
  updatePaymentSystemId,
  getWhiteLabelTabData,
  getEmailtemplate,
  updateCompanyDetails,
  clearCompanyTabDetails,
  getCountryDetailsSuccess
} = companyTabData.actions;

// ==============================|| Get Company Details ||============================== //
export function fetchCompanyDetails(companyId: string) {
  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/getCompany/${companyId}`
    );
    if (response.data) {
      const finalData = response.data.data;

      dispatch(
        getCompanyTabData({
          companyId: get(finalData, "_id", ""),
          companyName: get(finalData, "company_name", ""),
          contactPerson: get(finalData, "contact_person", ""),
          companyPhone: get(finalData, "phone_company", ""),
          companyEmail: get(finalData, "email_company", ""),
          companyMemberSince: get(finalData, "created_date", ""),
          city: get(finalData, "city", ""),
          abn: get(finalData, "abn", ""),
          systemName: get(finalData, "system_id.system_name", ""),
          allowablePercentageDrawdown: get(
            finalData,
            "limit_allowable_percent_drawdown",
            ""
          ),
          minimumWithdrawl: get(finalData, "min_withdrawal", ""),
          transactionFee: get(finalData, "transaction_fee_value", ""),
          transactionFeeType:
            get(finalData, "transaction_fee_type", "") === "DOLLAR"
              ? "$"
              : get(finalData, "transaction_fee_type", "") === "PERCENT" && "%",
          totalNumberOfEmployees: get(
            finalData,
            "limit_number_of_employee",
            ""
          ),
          totalAmountPerCompany: get(finalData, "limit_money", ""),
          floatAlertThresholdAmount: get(finalData, "threshold_amount", ""),
          floatAlertRecipients: get(
            finalData,
            "recipients_float_alert",
            []
          ).toString(),
          systemPayrollId: get(finalData, "system_id._id", ""),
          systemPayrollCode: get(finalData, "system_id.code", ""),
          cashDCapitalId: get(finalData, "keypay_deduction_category_id", ""),
          cashDFeeId: get(finalData, "keypay_deduction_category_fee_id", ""),
          activePayroll: get(finalData, "is_payroll_setup", false),
          systemCode: get(finalData, "system_id.code", ""),
          includeSubmitted: get(finalData, "is_system_approve_process", false),
          apiPassword : get(finalData,"system_api_password", ""),
          apiUserName: get(finalData, "system_api_username",""),
          apiKey : get(finalData, "x_api_key",""),
          baseUrl :get(finalData, "baseUrl" ,""),
          userName : get(finalData, "userName",""),
          userPassword : get(finalData, "userPassword",""),
          countryName: get(finalData, "countryName", ""),
          currencyCode: get(finalData, "countryCurrencyCode", ""),
          currencySymbol: get(finalData, "currencySymbol", ""),
          editCountryDetails: get(finalData, "enable_country_feature", false),
          countryID: get(finalData, "country_id", ""),
          paymentSystemId: get(finalData, "payment_system_id", "")
        })
      );
      dispatch(
        getBankingTabData({
          bankingEnvironment: get(finalData, "is_monoova_live_mode", false),
          bankingValidateBankAccount: get(
            finalData,
            "is_validate_bank_account",
            false
          ),
          paymentSystemId: get(finalData, "payment_system_id", ""),
        })
      );
      dispatch(
        getWhiteLabelTabData({
          primaryColor: get(finalData, "brand.primaryColor", "Default"),
          secondaryColor: get(finalData, "brand.secondaryColor", "Default"),
          logoUrl: get(finalData, "brand.logoUrl", "Default"),
          custom: get(finalData, "brand.custom", false),
        })
      );
      dispatch(
        getEmailtemplate({
          emailLogo: get(finalData, "emailTemplateLogo.location", ""),
        })
      );
    }
  };
}

// ==============================|| Save Company Details ||============================== //
export function saveCompanyTabData(
  companyId: string,
  payload: any,
  navigator: any
) {
  return async () => {
    const response = await authApi.put(
      `/v2/api/companies/editCompany/${companyId}`,
      payload
    );
    if (response.data) {
      toast(T.COMPANY_DETAILS_HAS_BEEN_SAVED_SUCCESSFULY, {
        variant: "success",
      });
      navigator("/companyDetails/company/view");
    }
  };
}

export function getCountryDetails() {

  return async () => {
    const response = await authApi.get(
      `/v2/api/companies/getCountries`
    );
    if (response.data) {
      dispatch(
        getCountryDetailsSuccess({
          countryList: response.data.data,
        })
      );
     
    }
  };
}

// ==============================|| ADD NEW COUNTRY ||============================== //
export function addNewCountry(
  payload: any,
  navigator: any
) {
  return async () => {
    const response = await authApi.post(
      `/v2/api/companies/addNewCountry`,
      payload
    );
    if (response.data) {
      dispatch(getCountryDetails());
      toast(T.NEW_COUNTRY_HAS_BEEN_ADDED, {
        variant: "success",
      });
      navigator("/companyDetails/company/edit");
    }
  };
}
