import { SECRET_ENCRYPTION_KEY } from "./ServerConstants";
import CryptoJS from "crypto-js";

export const decryptBankingData = (text: string | number) => {
  if (!text || !SECRET_ENCRYPTION_KEY) return text;
  const decryptedData = CryptoJS.AES.decrypt(
    text.toString(),
    SECRET_ENCRYPTION_KEY
  ).toString(CryptoJS.enc.Utf8);
  return JSON.parse(JSON.stringify(decryptedData));
};
