import React, { useReducer, useEffect, useCallback, useMemo } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import toast from "utils/ToastNotistack";
import MainCard from "components/MainCard";
import T from "utils/constants/T";
import { StyledBodyTypography } from "../CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";
import CashDAutocomplete from "components/common/CashDAutocomplete";
import { BANKING_ENVIRONMENT_LIST } from "utils/constants/companyDetails";
import ViewBankingDetails from "./ViewBankingDetails";
import {
  getExistingPaymentPartner,
  getPaymentPartnersById,
  updateBankingEnvironment,
  updatePaymentPartner,
} from "store/reducers/companyBankingSettings";
import CashDDialog from "components/common/CashDDialog";
import ChangeLenderDetails from "./ChangeLenderDetails";
import { updateCompanyDetails } from "store/reducers/companyTabDetails";

import "../../companyDetails.css";
import "./banking.css";

type LocalStateType = {
  paymentPartner: any;
  environment: string;
  openLenderChangeModal: boolean;
};

const NO_PAYMENT_PARTNER = { code: "" };

const EditPayrollSettings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state selectors
  const selectedCompany = useSelector((state: any) => state.dashboardSearch.selectedCompany);
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const { companyName, paymentSystemId, bankingEnvironment } = useSelector(
    (state: any) => state.companyTabData
  );
  const {
    paymentPartnerList,
    existingPaymentPartner,
    accountNumber,
    lenderId,
    fundingType,
    nameOfBank,
    balance,
    accountName,
    bsb,
    interestRate,
  } = useSelector((state: any) => state.bankingSettingsTabData);

  const existingPaymentPartnerObj = useMemo(
    () =>
      paymentPartnerList.find(
        (partner: any) => partner.code === existingPaymentPartner
      ) || NO_PAYMENT_PARTNER,
    [paymentPartnerList, existingPaymentPartner]
  );

  // Local state management
  const [localState, setLocalState] = useReducer(
    (prevState: LocalStateType, newState: Partial<LocalStateType>) => ({
      ...prevState,
      ...newState,
    }),
    {
      paymentPartner: existingPaymentPartnerObj,
      environment: bankingEnvironment ? T.LIVE : T.TEST,
      openLenderChangeModal: false,
    }
  );

  const { paymentPartner, environment, openLenderChangeModal } = localState;

  useEffect(() => {
    if (paymentSystemId) {
      dispatch(getPaymentPartnersById(paymentSystemId));
    } else {
      dispatch(getExistingPaymentPartner({ existingPaymentPartner: {} }));
      setLocalState({ paymentPartner: {} });
    }
  }, [paymentSystemId, dispatch]);

  // Save settings handler
  const handleSaveSettings = useCallback(async () => {
    try {
      // Save Payment Partner
      if (paymentPartner && paymentPartner._id) {
        const paymentPartnerPayload = {
          companyId: selectedCompanyId,
          paymentSystemId: paymentPartner._id,
        };
        await dispatch(updatePaymentPartner(paymentPartnerPayload));
      }
  
      // Save Banking Environment
      const bankingPayload = {
        lenderId,
        is_monoova_live_mode: environment === T.LIVE,
      };
      await dispatch(updateBankingEnvironment(selectedCompanyId, bankingPayload));
  
      // Update company details locally
      dispatch(
        updateCompanyDetails({
          key: "bankingEnvironment",
          value: bankingPayload.is_monoova_live_mode,
        })
      );
  
      // Navigate back after successful saving
      navigate("/companyDetails/bankingSettings/view");
    } catch (error) {
      console.error("Error saving settings:", error);
      toast(("Error saving settings"), { variant: "error" });
    }
  }, [paymentPartner, environment, lenderId, selectedCompanyId, dispatch, navigate]);
  
  const handleBankingEnvironmentChange = useCallback(
    (type: string, newValue: any) => {
      setLocalState({ [type]: newValue } as Partial<LocalStateType>);
    },
    []
  );

  const handlePaymentPartnerChange = useCallback(
    (type: string, newValue: any) => {
      setLocalState({ [type]: newValue } as Partial<LocalStateType>);
    },
    []
  );

  const handleLenderChangeModal = useCallback(
    () =>
      setLocalState({
        openLenderChangeModal: !localState.openLenderChangeModal,
      }),
    [localState]
  );

  const renderViewBankingData = useMemo(
    () => ({
      accountName,
      fundingType,
      nameOfBank,
      bsb,
      accountNumber,
      balance,
      interestRate,
    }),
    [accountName, fundingType, nameOfBank, bsb, accountNumber, balance, interestRate]
  );

  return (
    <>
      <MainCard
        title={companyName}
        secondary={
          <Button
            variant="contained"
            className="main-card-button"
            onClick={handleSaveSettings}
          >
            {T.SAVE}
          </Button>
        }
      >
        <CashDCard borderRadius="20px">
          <Grid container rowSpacing={4} columnSpacing={15}>
            <Grid item xs={12}>
              <Grid item xs={6}>
                <Grid container className="top-box" rowSpacing={1}>
                  <Grid item xs={6}>
                    <StyledBodyTypography>
                      {T.PAYMENT_PARTNER_SELECTION}
                    </StyledBodyTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <CashDAutocomplete
                      placeholder={T.CHOOSE_PAYMENT_PARTNER}
                      fullWidth
                      listDetails={paymentPartnerList}
                      value={paymentPartner || null}
                      getByLabelText={(option: any) => option.code}
                      onHandleChange={(event, newValue) =>
                        handlePaymentPartnerChange("paymentPartner", newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledBodyTypography>{T.ENVIRONMENT}</StyledBodyTypography>
                  </Grid>
                  <Grid item xs={6}>
                    <CashDAutocomplete
                      disabled={!lenderId}
                      placeholder={T.CHOOSE_SETTING}
                      fullWidth
                      listDetails={BANKING_ENVIRONMENT_LIST}
                      value={environment}
                      getByLabelText={(option: any) => option}
                      onHandleChange={(event, newValue) =>
                        handleBankingEnvironmentChange("environment", newValue)
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CashDCard>

        {lenderId ? (
          <CashDCard borderRadius="20px">
            <ViewBankingDetails {...renderViewBankingData} />
            <Divider />
            <Box className="add-button">
              <Button variant="contained" onClick={handleLenderChangeModal}>
                {T.CHANGE}
              </Button>
            </Box>
          </CashDCard>
        ) : (
          <CashDCard borderRadius="20px">
            <Typography className="lender-heading">
              {T.LENDER_DETAILS}
            </Typography>
            <Box className="add-button">
              <Button variant="contained" onClick={handleLenderChangeModal}>
                {T.ADD}
              </Button>
            </Box>
          </CashDCard>
        )}
      </MainCard>

      <CashDDialog
        open={openLenderChangeModal}
        handleClose={handleLenderChangeModal}
      >
        <Box className="header-box">
          <Typography variant="h4" className="view-table-heading">
            {T.SELECT_LENDER}
          </Typography>
          <IconButton onClick={handleLenderChangeModal}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
        <ChangeLenderDetails
          selectedCompanyId={selectedCompanyId}
          handleLenderChangeModal={handleLenderChangeModal}
        />
      </CashDDialog>
    </>
  );
};


export default React.memo(EditPayrollSettings);
