//React
import { useEffect, useReducer } from "react";

//react-redux
import { useDispatch } from "react-redux";
import { useSelector } from "store";
import ViewPayrollSettingsTimeSheet from "./ViewPayrollSettingsTimeSheet";
import ViewPayrollSettings from "./ViewPayrollSettings";
import T from "utils/constants/T";
import { fetchPayrollSettingsTabData } from "store/reducers/companyPayrollSettings";
import { get } from "lodash";
import MainCard from "components/MainCard";
import ViewPayrollSettingsMYOB from "./ViewPayrollSettingsMYOB";
const ViewPayrollTab = () => {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const { companyName, systemName } = useSelector(
    (state: any) => state.companyTabData
  );
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const { deductionFileCategories } = useSelector(
    (state: any) => state.payrollSettingsTabData
  );
  const { role } = useSelector((state) => state.auth) as {
    role: string;
  };
  const {
    systemPayrollCode,
    cashDCapitalId: storedCashDCapitalId,
    cashDFeeId: storedCashDFeeId,
    includeSubmitted,
    activePayroll,
    systemCode,
    apiKey,
    apiUserName,
    apiPassword,
    baseUrl,
    userName,
    userPassword
  } = useSelector((state: any) => state.companyTabData);

  console.log(systemCode)
  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      cashDCapital: {},
      cashDFee: {},
    }
  );

  const { cashDCapital, cashDFee } = localState;
console.log(systemName)

  useEffect(() => {
    if (selectedCompanyId) {
        if(systemCode!== "XERO" && systemCode!=="DEPUTY" && activePayroll){
            dispatch(
                fetchPayrollSettingsTabData(selectedCompanyId, systemPayrollCode)
              );
        }
      
    }
  }, [systemName]);

  useEffect(() => {
    if (storedCashDCapitalId && storedCashDFeeId) {
      setLocalState({
        cashDCapital: getCategory(storedCashDCapitalId),
        cashDFee: getCategory(storedCashDFeeId),
      });
    }
  }, [storedCashDFeeId, storedCashDCapitalId]);

  const getCategory = (id: string) => {
    const category = deductionFileCategories.find(
      (item: any) => item._id === id
    );
    return category;
  };

  return (
    <>
      {systemPayrollCode === T.ASTUTE.toUpperCase() ? (
        <ViewPayrollSettingsTimeSheet
          includeSubmitted={includeSubmitted}
          companyName={companyName}
          systemName={systemName}
          apiKey= {apiKey}
          userName={apiUserName}
          password={apiPassword}
          role={role}
        />
      ) : systemPayrollCode === T.KEYPAY.toUpperCase() ? (
        <ViewPayrollSettings
          cashDCapitalCategory={cashDCapital}
          cashDCapitalFeeCategory={cashDFee}
          companyName={companyName}
          systemName={systemName}
          role={role}
        />
      ) : systemPayrollCode === T.MYOBADVANCED.toUpperCase() ? (
        <ViewPayrollSettingsMYOB
          baseURL={baseUrl}
          userName={userName}
          userPassword={userPassword}
          systemName={systemName}
        />
      ) : (
        <MainCard title={systemName}></MainCard>
      )}
    </>
  );
};

export default ViewPayrollTab;
