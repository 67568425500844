import ViewBankingTableHeader from "./ViewBankingTableHeader";
import ViewBankingTableBody from "./ViewBankingTableBody";
import { VIEW_BANKING_TABLE_HEADER } from "utils/constants/companyDetails";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import T from "utils/constants/T";
import {
  Table as TableView,
  Paper,
  TableContainer,
  Box,
  Card,
} from "@mui/material";

interface ViewBankingTableProps {
  accountName?: string;
  fundingType?: string;
  nameOfBank?: string;
  bsb?: string;
  accountNumber?: string;
  balance?: string;
  interestRate?: string;
}

const ViewBankingTable = ({
  accountName = "",
  fundingType = "",
  nameOfBank = "",
  bsb = "",
  accountNumber = "",
  balance = "",
  interestRate = "",
}: ViewBankingTableProps) => {
  const { paymentPartner } = useSelector(
    (state: any) => state.bankingSettingsTabData
  );

  // Memoize the columns to avoid recomputing them on every render
  const columns = useMemo(() => {
    return paymentPartner === T.MONOOVA
      ? VIEW_BANKING_TABLE_HEADER
      : VIEW_BANKING_TABLE_HEADER.filter((h) => h.label !== T.BSB);
  }, [paymentPartner]);

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 0px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          sx={{ height: "inherit", overflow: "auto", padding: "0px" }}
        >
          <TableView stickyHeader>
            <ViewBankingTableHeader columns={columns} />
            <ViewBankingTableBody
              accountName={accountName}
              fundingType={fundingType}
              nameOfBank={nameOfBank}
              bsb={bsb}
              accountNumber={accountNumber}
              balance={balance}
              interestRate={interestRate}
            />
          </TableView>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default React.memo(ViewBankingTable);
