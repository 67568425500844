import React, { useMemo, useCallback } from "react";
import { EDIT_BANKING_TABLE_HEADER } from "utils/constants/companyDetails";
import EditBankingTableBody from "./EditBankingTableBody";
import { noop } from "lodash";
import EditBankingTableHeader from "./EditBankingTableHeader";
import { useSelector } from "react-redux";
import T from "utils/constants/T";
import {
  Table as TableView,
  Paper,
  TableContainer,
  Box,
  Card,
} from "@mui/material";

interface EditBankingTableProps {
  allTableRows?: any[];
  handleAddChangeLender?: (id: string, accountName: string) => void;
}

const EditBankingTable = ({
  allTableRows = [],
  handleAddChangeLender = noop,
}: EditBankingTableProps) => {
  const { paymentPartner } = useSelector((state: any) => state.bankingSettingsTabData);

  const columns = useMemo(() => {
    return paymentPartner === T.MONOOVA
      ? EDIT_BANKING_TABLE_HEADER
      : EDIT_BANKING_TABLE_HEADER.filter((h) => h.label !== T.BSB);
  }, [paymentPartner]);

  const memoizedHandleAddChangeLender = useCallback(
    (id: string, accountName: string) => {
      handleAddChangeLender(id, accountName);
    },
    [handleAddChangeLender]
  );

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 0px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          sx={{ height: "inherit", overflow: "auto", padding: "0px" }}
        >
          <TableView stickyHeader>
            <EditBankingTableHeader columns={columns} />
            <EditBankingTableBody
              allTableRows={allTableRows}
              handleAddChangeLender={memoizedHandleAddChangeLender}
            />
          </TableView>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default React.memo(EditBankingTable);
