//RTK
import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

//Local imports
import { AppDispatch, dispatch, RootState } from "store";
// import ServerConstants from "utils/ServerConstants";
import toast from "utils/ToastNotistack";

//Lodash
import { get } from "lodash";
import T from "utils/constants/T";
// import { cashdCurrentUser } from "utils/constants/validations";
import authApi from "interceptor/authInterceptor";
import { updatePaymentSystemId } from "./companyTabDetails";
import { decryptBankingData } from "utils/helper";

// const { SERVER_URL } = ServerConstants;

//types
export type bankingSettingsTabProps = {
  paymentPartner: string;
  existingPaymentPartner: string;
  paymentPartnerList: any[];
  accountNumber: string | number;
  nameOfLender: string;
  lenderId: string;
  lendersList: any[];
  credit: string | number;
  fundingType: string;
  debit: string | number;
  nameOfBank: string;
  balance: string | number;
  accountName: string;
  dateAndTime: string;
  bsb: string;
  interestRate: string;
  loader: boolean;
  error: null;
};
//initial state
const initialState: bankingSettingsTabProps = {
  paymentPartner: "",
  existingPaymentPartner: "",
  paymentPartnerList: [],
  accountNumber: "",
  nameOfLender: "",
  lenderId: "",
  lendersList: [],
  credit: "",
  fundingType: "",
  debit: "",
  nameOfBank: "",
  balance: "",
  accountName: "",
  dateAndTime: "",
  bsb: "",
  interestRate: "",
  loader: false,
  error: null,
};
// ==============================|| SLICE - Banking Settings Tab ||============================== //
const bankingSettingsTabData = createSlice({
  name: "bankingSettingsTabData",
  initialState,
  reducers: {
    getBankingSettingsTabData(state, action) {
      let {
        paymentPartner,
        accountNumber,
        nameOfLender,
        lenderId,
        credit,
        fundingType,
        debit,
        nameOfBank,
        balance,
        accountName,
        dateAndTime,
        bsb,
        interestRate,
      } = action.payload;
      state.paymentPartner = paymentPartner || initialState.paymentPartner;
      state.accountNumber = accountNumber || initialState.accountNumber;
      state.nameOfLender = nameOfLender || initialState.nameOfLender;
      state.lenderId = lenderId || initialState.lenderId;
      state.credit = credit || initialState.credit;
      state.fundingType = fundingType || initialState.fundingType;
      state.debit = debit || initialState.debit;
      state.nameOfBank = nameOfBank || initialState.nameOfBank;
      state.balance = balance || initialState.balance;
      state.accountName = accountName || initialState.accountName;
      state.dateAndTime = dateAndTime || initialState.dateAndTime;
      state.bsb = bsb || initialState.bsb;
      state.interestRate = interestRate || initialState.interestRate;
    },
    getLendersList(state, action) {
      let { lendersList } = action.payload;
      state.lendersList = lendersList || initialState.lendersList;
    },
    getPaymentPartnerList(state, action) {
      let { paymentPartnerList } = action.payload;
      state.paymentPartnerList =
        paymentPartnerList || initialState.paymentPartnerList;
    },
    getExistingPaymentPartner(state, action) {
      let { existingPaymentPartner } = action.payload;
      state.existingPaymentPartner = existingPaymentPartner || initialState.existingPaymentPartner;
      state.paymentPartner = existingPaymentPartner
    },
    isLoading(state, action) {
      state.loader = action.payload;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    clearBankingSettings: (state) => initialState,
  },
});
export default bankingSettingsTabData.reducer;

export const {
  getBankingSettingsTabData,
  getPaymentPartnerList,
  getExistingPaymentPartner,
  getLendersList,
  isLoading,
  hasError,
  clearBankingSettings,
} = bankingSettingsTabData.actions;

// ==============================|| SLICE - Get Banking Settings Details ||============================== //

export function fetchBankingSettingsTabData(selectedCompanyId: string, providerType: string, bankingEnvironment: boolean | null) {
  return async () => {
    try {
      const response = await authApi.get(
        `/v2/api/companies/${selectedCompanyId}/linkedLender/${providerType}`
      );
      if (response.data) {
        const responseData = get(response, "data.data", {});
        console.log("response data", responseData);
        const finalData = responseData;
        const creditVal = get(finalData, "total_amount", "");
        const debitVal = get(finalData, "total_deduction", "");
        const iRateVal = get(finalData, "interest_rate_value", "");
        const iRateType =
          get(finalData, "interest_rate_type", "") === T.DOLLARTEXT
            ? T.DOLLAR
            : get(finalData, "interest_rate_type", "") === T.PERCENT &&
            T.PERCENTAGE;
        const finalInterestRate =
          iRateType === T.PERCENTAGE
            ? `${iRateVal}${iRateType}`
            : `${iRateType}${iRateVal}`;

        let accountName = "";
        let accountNumber = "";
        let bsb = "";
        let balance = 0;

        if(providerType === T.MONOOVA) {
          balance = get(finalData, "monoova_balance", 0).toString();
          if(bankingEnvironment) {
            accountName = get(finalData, `live_receivables_account_name`, "");
            accountNumber = decryptBankingData(get(finalData,`live_receivables_account_number`, ""));
            bsb = decryptBankingData(get(finalData, `live_receivables_account_bsb`, ""));
          } else {
            accountName =  get(finalData, `test_receivables_account_name`, "");
            accountNumber = decryptBankingData(get(finalData,`test_receivables_account_number`, ""));
            bsb = decryptBankingData(get(finalData, `test_receivables_account_bsb`, ""));
          }
        } else if(providerType === T.NIUM) {
          accountName = get(finalData, `lender_name`, "");
          accountNumber = bankingEnvironment ? get(finalData, `nium_live_account_number`, "") : get(finalData, `nium_sandbox_account_number`, "");
          balance = get(finalData, "balance", 0).toString();
        }

        dispatch(isLoading(false));
        dispatch(
          getBankingSettingsTabData({
            paymentPartner: providerType,
            accountNumber,
            nameOfLender: get(finalData, "lender_name", ""),
            lenderId: get(finalData, "_id", ""),
            credit: creditVal,
            fundingType: get(finalData, "funding_type", ""),
            debit: debitVal,
            nameOfBank: providerType,
            balance,
            accountName,
            dateAndTime: get(finalData, "start_date", ""),
            bsb: bsb,
            interestRate: finalInterestRate,
          })
        );
      }
    } catch (error) {
      toast(get(error, "message", ""), { variant: "error" });
      dispatch(hasError(error));
    }
  };
}

// ==============================|| SLICE - Get All Lenders detailed List ||============================== //

export function fetchLendersDetailedList(selectedCompanyId: string) {
  return async () => {
    try {
      const response = await authApi.get(
        `/v2/api/companies/${selectedCompanyId}/lenders`
      );
      if (response.data) {
        const finalData = get(response, "data.result", []);
        dispatch(getLendersList({ lendersList: finalData }));
      }
    } catch (error) {
      toast(get(error, "message", ""), { variant: "error" });
      dispatch(hasError(error));
    }
  };
}

// ==============================|| SLICE - Update Lender  ||============================== //

export function UpdateCompanyLender(payload: any) {
  return async (dispatch: any, getState: any) => {
    const response = await authApi.put("v2/api/companies/lender", payload);
    // fetch and update company banking data in slice
    if (response.data.type === "success") {
      const { bankingEnvironment } = getState().companyTabData;
      // Dispatch the fetchBankingSettingsTabDataThunk with required parameters
      dispatch(fetchBankingSettingsTabData(payload.companyId,payload.providerType,bankingEnvironment));
      toast(get(response, "data.message", ""), { variant: "success" });
    }
  };
}

// ==============================|| SLICE - Get Banking Payment Partners List ||============================== //

export function fetchPaymentPartnersList() {
  return async () => {
    const response = await authApi.get(`/v2/api/companies/paymentPartners`);
    if (response.data) {
      const finalData = get(response, "data", {});
      dispatch(
        getPaymentPartnerList({
          paymentPartnerList: get(finalData, "allPaymentSystems", []),
        })
      );
    }
  };
}

// ==============================|| SLICE - Get Banking Payment Partners By Id  ||============================== //

export function getPaymentPartnersById(paymentSystemId: string) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const response = await authApi.get(
        `/v2/api/companies/paymentPartner/${paymentSystemId}`
      );

      if (response.data) {
        const finalData = get(response, "data", {});
        const existingPaymentPartner = get(
          finalData,
          "payment_system_code",
          ""
        );

        // Dispatch the action to update the existing payment partner
        dispatch(
          getExistingPaymentPartner({
            existingPaymentPartner,
          })
        );

        const { companyId, bankingEnvironment } = getState().companyTabData;
        // Dispatch the fetchBankingSettingsTabDataThunk with required parameters
        dispatch(
          fetchBankingSettingsTabData(
            companyId,
            existingPaymentPartner,
            bankingEnvironment
          )
        );
      }
    } catch (error) {
      console.error("Error in getPaymentPartnersById method:", error);
    }
  };
}

// ==============================|| SLICE - Update Bank Account Validation  ||============================== //

export function updateBankAccountValidate(companyId: string, payload: any) {
  return async () => {
    const response = await authApi.post(
      `/v2/api/companies/${companyId}/validateBankAccount`,
      payload
    );
    if (response.data) {
      dispatch(isLoading(false));
    }
  };
}

// ==============================|| SLICE - Update Payment Partner ||============================== //

export function updatePaymentPartner(payload: any) {
  return async () => {
    const response = await authApi.put(
      `/v2/api/companies/paymentPartner`,
      payload
    );

    if (response.data) {
      // update company payment system id in rtk slice
      dispatch(updatePaymentSystemId(payload.paymentSystemId));

      toast(get(response, "data.message", ""), { variant: "success" });
    }
  };
}

// ==============================|| SLICE - Update Banking Environment  ||============================== //

export function updateBankingEnvironment(companyId: string, payload: any) {
  return async () => {
    const response = await authApi.put(
      `/v2/api/companies/${companyId}/monoovaMode`,
      payload
    );
    if (response.data)
      toast(get(response, "data.message", ""), { variant: "success" });
  };
}
// ==============================|| SLICE - Sync Payment Partner Transactions  ||============================== //

export function SyncPaymentPartnerTransactions(companyId: string) {
  return async () => {
    await authApi.post(
      `/v2/api/companies/${companyId}/syncMonoovaTransactions`
    );
  };
}

// ==============================|| SLICE - Save Banking Settings ||============================== //

export function saveBankingSettingsTabData(payload: any) {
  return async () => {
    const response = await authApi.put(
      `/v2/api/companies/financialSettingupdate`,
      payload
    );
    if (response.data) {
      dispatch(
        getBankingSettingsTabData({
          totalNumberOfEmployees: payload.limit_number_of_employee,
          monthlyActiveEmployees: "",
          totalAmountPerCompany: payload.limit_money,
          totalAmountDrawnOnThisPeriod: "",
          allowablePercentageDrawdown: payload.limit_allowable_percent_drawdown,
          balanceAvailable: "",
          transactionFee: payload.transaction_fee,
          currencyType: payload.transaction_fee_type,
          floatAlertThresholdAmount: payload.threshold_amount,
          minimumWithdrawl: payload.min_withdrawal,
          floatAlertRecipients: payload.recipients_float_alert,
        })
      );
    }
  };
}
