//Material UI
import { Button, Grid } from "@mui/material";
// import CachedIcon from '@mui/icons-material/Cached';

//React-Router
import { useNavigate } from "react-router";

//Local Imports
import MainCard from "components/MainCard";
import T from "utils/constants/T";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";

//CSS
import "./payrollSettings.css";
import "../../companyDetails.css";
import { get } from "lodash";

interface ViewPayrollSettingsProps {
  cashDCapitalCategory: any;
  cashDCapitalFeeCategory: any;
  companyName: string;
  systemName: string;
  role: string;
}
const ViewPayrollSettings = ({
  cashDCapitalCategory = {},
  cashDCapitalFeeCategory = {},
  companyName = "",
  systemName = "",
  role = "",
}: ViewPayrollSettingsProps) => {
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate("/companyDetails/payrollSettings/edit");
  };

  console.log(role,"ROLE")

  return (
    <MainCard
      title={systemName}
      secondary={
        <>
          {/* <Button
                        variant="outlined"
                        className='main-card-button'
                        startIcon={<CachedIcon />}
                    >
                        {T.SYNC_PAYROLL}
                    </Button>
                    &nbsp;&nbsp;&nbsp; */}
          {role === "superadmin" && (
            <Button
              variant="contained"
              className="main-card-button"
              onClick={handleEdit}
            >
              {T.EDIT}
            </Button>
          )}
        </>
      }
    >
      <StyledBodyTypography>
        {T.DEDUCTION_FILE_CATEGORY_SELECTION}
      </StyledBodyTypography>
      <CashDCard borderRadius="20px">
        <Grid container>
          <Grid item xs={6}>
            <StyledTitleTypography>{T.CASH_D_CAPITAL}</StyledTitleTypography>
            <StyledBodyTypography>
              {get(cashDCapitalCategory, "name", "")}
            </StyledBodyTypography>
          </Grid>
          <Grid item xs={6}>
            <StyledTitleTypography>{T.CASH_D_FEE}</StyledTitleTypography>
            <StyledBodyTypography>
              {get(cashDCapitalFeeCategory, "name", "")}
            </StyledBodyTypography>
          </Grid>
        </Grid>
      </CashDCard>
    </MainCard>
  );
};

export default ViewPayrollSettings;
