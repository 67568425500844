import React, { useCallback } from "react";
import noop from "lodash/noop";

import {
  Table as TableView,
  Paper,
  TableContainer,
  Box,
  Card,
} from "@mui/material";
import TableHeader from "../TableHeader";
import { PAGINATION } from "utils/constants/pagination";
import { TRANSACTION_TABLE_HEADER } from "utils/constants/transactions";
import TransactionsTableBody from "./TransactionsTableBody";
import MasterPagination from "utils/MasterPagination";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

interface TransactionsTableProps {
  page?: number;
  onPageChange?: (page: number) => void;
  rowsPerPage?: number;
  onRowsPerPageChange?: (rows: number) => void;
  totalTableRowsCount?: number;
  totalPageCount?: number;
  allTableRows?: any;
  handleAddEditProjectInfoDialog?: () => void;
  handleDeleteProjectDialog?: () => void;
}

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  page = INITIAL_PAGE,
  onPageChange = noop,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange = noop,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
  handleAddEditProjectInfoDialog = noop,
  handleDeleteProjectDialog = noop,
}) => {
  const memoizedOnPageChange = useCallback(onPageChange, []);
  const memoizedOnRowsPerPageChange = useCallback(onRowsPerPageChange, []);

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 0px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          sx={{ height: "inherit", overflowY: "auto" }}
        >
          <TableView stickyHeader>
            <TableHeader columns={TRANSACTION_TABLE_HEADER} />
            <TransactionsTableBody allTableRows={allTableRows} />
          </TableView>
        </TableContainer>
      </Box>
      <MasterPagination
        page={page}
        onPageChange={memoizedOnPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={memoizedOnRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

export default React.memo(TransactionsTable);
