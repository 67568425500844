import {
  TableRow,
  TableCell,
  TableBody,
  styled,
} from '@mui/material';
import { memo } from "utils/memo";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
}));

interface ViewBankingTableBodyProps {
  accountName?: string;
  fundingType?: string;
  nameOfBank?: string;
  bsb?: string;
  accountNumber?: string;
  balance?: string;
  interestRate?: string;
}

const ViewBankingTableBody = ({
  accountName = "",
  fundingType = "",
  nameOfBank = "",
  bsb = "",
  accountNumber = "",
  balance = "",
  interestRate = "",
}: ViewBankingTableBodyProps) => {
  return (
    <TableBody>
      <TableRow sx={{ background: "white" }}>
        <StyledTableCell>{accountName}</StyledTableCell>
        <StyledTableCell>{fundingType}</StyledTableCell>
        <StyledTableCell>{nameOfBank}</StyledTableCell>
        {bsb && <StyledTableCell>{bsb}</StyledTableCell>}
        <StyledTableCell>{accountNumber}</StyledTableCell>
        <StyledTableCell>{balance}</StyledTableCell>
        <StyledTableCell>{interestRate}</StyledTableCell>
      </TableRow>
    </TableBody>
  );
};

export default memo(ViewBankingTableBody);
