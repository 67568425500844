import { Children, useMemo, useCallback } from "react";
import {
  Chip,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { get } from "lodash";
import "./transactionTable.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
}));

interface TransactionsTableBodyProps {
  allTableRows: any[];
}

const TransactionsTableBody = ({
  allTableRows = [],
}: TransactionsTableBodyProps) => {
  // Memoizing rows to avoid recalculating on every render
  const rows = useMemo(() => {
    return allTableRows.map((record, index) => {
      const status = get(record, "status", "PENDING");
      const transactionDate = get(record, "Date", "");
      const date = new Date(transactionDate);
      const formattedDate = format(date, "dd MMM yyyy h:mm a");
      const payPeriod = get(record, "pay_periods", "");
      const systemPayrollId = get(record, "system_payroll_id", "N/A");
      const scheduleName = get(record, "schedule_details.payPeriodName", "");
      const employeeName = get(record, "employee_name", "");
      const companyName = get(record, "company_name", "");
      const transactionId = get(record, "transaction_id", "");
      const debit = get(record, "debit", "");
      const fee = get(record, "fee", "");

      return {
        index,
        formattedDate,
        companyName,
        payPeriod,
        scheduleName,
        employeeName,
        systemPayrollId,
        transactionId,
        debit,
        fee,
        status,
      };
    });
  }, [allTableRows]);

  // Function to render each row (memoized)
  const renderRow = useCallback((row: any) => {
    const {
      index,
      formattedDate,
      companyName,
      payPeriod,
      scheduleName,
      employeeName,
      systemPayrollId,
      transactionId,
      debit,
      fee,
      status,
    } = row;

    return (
      <TableRow sx={{ background: "white" }} key={index}>
        <StyledTableCell>
          <Box className="table-column">{formattedDate}</Box>
        </StyledTableCell>
        <StyledTableCell>{companyName}</StyledTableCell>
        <StyledTableCell>
          <Box className="table-column">{payPeriod}</Box>
        </StyledTableCell>
        <StyledTableCell>{scheduleName || 'N/A'}</StyledTableCell>
        <StyledTableCell>{employeeName}</StyledTableCell>
        <StyledTableCell>{systemPayrollId}</StyledTableCell>
        <StyledTableCell>{transactionId}</StyledTableCell>
        <StyledTableCell>{`$${debit}`}</StyledTableCell>
        <StyledTableCell>{`$${fee}`}</StyledTableCell>
        <StyledTableCell>
          <Chip
            color={
              status === "PENDING"
                ? "error"
                : status === "COMPLETED"
                ? "success"
                : "info"
            }
            label={status}
            size="small"
            variant="light"
          />
        </StyledTableCell>
      </TableRow>
    );
  }, []);

  return (
    <TableBody>
      {Children.toArray(rows.map(renderRow))}

      {allTableRows.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan={10} sx={{ border: "none" }}>
            <Typography variant="body1" textAlign="center">
              No Records
            </Typography>
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

export default React.memo(TransactionsTableBody);
