import React, { useMemo } from "react";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import { get } from "lodash";

const ViewBankingTableHeader = ({ columns = [] }: { columns: any[] }) => {
  const tableCells = useMemo(
    () =>
      columns.map((column: any) => (
        <TableCell
          key={get(column, "key", column.label)}
          align={get(column, "align", "left")}
          sx={{
            border: "none",
            textTransform: "capitalize",
            padding: 1,
          }}
        >
          <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
            {column.label}
          </Typography>
        </TableCell>
      )),
    [columns]
  );

  return (
    <TableHead>
      <TableRow>{tableCells}</TableRow>
    </TableHead>
  );
};

export default React.memo(ViewBankingTableHeader);
