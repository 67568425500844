// Material-UI imports
import {
  TableRow,
  TableCell,
  TableBody,
  styled,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

// Local imports
import { memo } from "utils/memo";
import { get, noop } from "lodash";
import T from "utils/constants/T";
import { maskNumber } from "utils/CommonMethods";
import { useMemo } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
}));

interface EditBankingTableBodyProps {
  allTableRows: any[];
  handleAddChangeLender: (id: string, accountName: string) => void;
}

const EditBankingTableBody = ({
  allTableRows = [],
  handleAddChangeLender = noop,
}: EditBankingTableBodyProps) => {
  // Memoize the rows to avoid recalculation on each render if data hasn't changed
  const rows = useMemo(() => {
    return allTableRows.map((record, index) => {
      const lenderId = get(record, "lenderId", "");
      const lenderAccountName = get(record, "accountName", "");
      return (
        <TableRow sx={{ background: "white" }} key={index}>
          <StyledTableCell
            onClick={() => handleAddChangeLender(lenderId, lenderAccountName)}
          >
            <AddCircleIcon fontSize="medium" className="add-icon" />
          </StyledTableCell>
          <StyledTableCell>{record.accountName}</StyledTableCell>
          <StyledTableCell>{record.fundingType}</StyledTableCell>
          <StyledTableCell>{record.nameOfBank}</StyledTableCell>
          {record.bsb && <StyledTableCell>{record.bsb}</StyledTableCell>}
          <StyledTableCell>
            {record.accountNumber ? maskNumber(record.accountNumber) : ""}
          </StyledTableCell>
          <StyledTableCell>{record.balance}</StyledTableCell>
          <StyledTableCell>{record.interestRate}</StyledTableCell>
        </TableRow>
      );
    });
  }, [allTableRows, handleAddChangeLender]);

  // Memoized empty state row (if there are no records)
  const emptyStateRow = useMemo(() => {
    return (
      <TableRow>
        <StyledTableCell colSpan={10} sx={{ border: "none" }}>
          <Typography variant="body1" textAlign="center">
            {T.NO_RECORDS}
          </Typography>
        </StyledTableCell>
      </TableRow>
    );
  }, []);

  return <TableBody>{rows.length > 0 ? rows : emptyStateRow}</TableBody>;
};

// Memoize the EditBankingTableBody component to avoid unnecessary re-renders
export default memo(EditBankingTableBody);
